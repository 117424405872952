const AP_COUNTRY_LIST = {
  BD: {
    code: 'BD',
    name: 'Bangladesh',
    index: 0,
    geid: 'FP_BD',
    currency: { symbol: '৳', code: 'BDT' },
  },
  HK: {
    code: 'HK',
    name: 'Hong Kong',
    index: 1,
    geid: 'FP_HK',
    currency: { symbol: '$', code: 'HKD' },
  },
  KH: {
    code: 'KH',
    name: 'Cambodia',
    index: 2,
    geid: 'FP_KH',
    currency: { symbol: '៛', code: 'KHR' },
  },
  LA: { code: 'LA', name: 'Laos', index: 3, geid: 'FP_LA', currency: { symbol: '₭', code: 'LAK' } },
  MM: {
    code: 'MM',
    name: 'Myanmar',
    index: 4,
    geid: 'FP_MM',
    currency: { symbol: 'K', code: 'MMK' },
  },
  MY: {
    code: 'MY',
    name: 'Malaysia',
    index: 5,
    geid: 'FP_MY',
    currency: { symbol: 'RM', code: 'MYR' },
  },
  PK: {
    code: 'PK',
    name: 'Pakistan',
    index: 6,
    geid: 'FP_PK',
    currency: { symbol: '₨', code: 'PKR' },
  },
  PH: {
    code: 'PH',
    name: 'Philippines',
    index: 7,
    geid: 'FP_PH',
    currency: { symbol: '₱', code: 'PHP' },
  },
  SG: {
    code: 'SG',
    name: 'Singapore',
    index: 8,
    geid: 'FP_SG',
    currency: { symbol: '$', code: 'SGD' },
  },
  TW: {
    code: 'TW',
    name: 'Taiwan',
    index: 9,
    geid: 'FP_TW',
    currency: { symbol: '$', code: 'TWD' },
  },
  TH: {
    code: 'TH',
    name: 'Thailand',
    index: 10,
    geid: 'FP_TH',
    currency: { symbol: '฿', code: 'THB' },
  },
  JP: {
    code: 'JP',
    name: 'Japan',
    index: 19,
    geid: 'FP_JP',
    currency: { symbol: '¥', code: 'JPY' },
  },
};
const EU_COUNTRY_LIST = {
  TR: {
    code: 'TR',
    name: 'Türkiye',
    index: 11,
    geid: 'YS_TR',
    currency: { symbol: '₺', code: 'TRY' },
  },
  FI: {
    code: 'FI',
    name: 'Finland',
    index: 14,
    geid: 'PO_FI',
    currency: { symbol: '€', code: 'EUR' },
  },
  NO: {
    code: 'NO',
    name: 'Norway',
    index: 15,
    geid: 'FO_NO',
    currency: { symbol: 'kr', code: 'NOK' },
  },
  SE: {
    code: 'SE',
    name: 'Sweden',
    index: 16,
    geid: 'OP_SE',
    currency: { symbol: 'kr', code: 'SEK' },
  },
  CZ: {
    code: 'CZ',
    name: 'Czech',
    index: 17,
    geid: 'DJ_CZ',
    currency: { symbol: 'Kč', code: 'CZK' },
  },
  HU: {
    code: 'HU',
    name: 'Hungary',
    index: 18,
    geid: 'NP_HU',
    currency: { symbol: 'Ft', code: 'HUF' },
  },
  AT: {
    code: 'AT',
    name: 'Austria',
    index: 20,
    geid: 'MJM_AT',
    currency: { symbol: '€', code: 'EUR' },
  },
  DE: {
    code: 'DE',
    name: 'Germany',
    index: 21,
    geid: 'FP_DE',
    currency: { symbol: '€', code: 'EUR' },
  },
};
const MENA_COUNTRY_LIST = {
  BH: {
    code: 'BH',
    name: 'Bahrain',
    index: 23,
    geid: 'TB_BH',
    currency: { symbol: 'BHD', code: 'BHD' },
  },
  EG: {
    code: 'EG',
    name: 'Egypt',
    index: 24,
    geid: 'HF_EG',
    currency: { symbol: '£', code: 'EGP' },
  },
  IQ: {
    code: 'IQ',
    name: 'Iraq',
    index: 25,
    geid: 'TB_IQ',
    currency: { symbol: 'IQD', code: 'IQD' },
  },
  JO: {
    code: 'JO',
    name: 'Jordan',
    index: 26,
    geid: 'TB_JO',
    currency: { symbol: 'JD', code: 'JOD' },
  },
  KW: {
    code: 'KW',
    name: 'Kuwait',
    index: 27,
    geid: 'TB_KW',
    currency: { symbol: 'KD', code: 'KWD' },
  },
  OM: {
    code: 'OM',
    name: 'Oman',
    index: 28,
    geid: 'TB_OM',
    currency: { symbol: 'R.O.', code: 'OMR' },
  },
  QA: {
    code: 'QA',
    name: 'Qatar',
    index: 29,
    geid: 'TB_QA',
    currency: { symbol: 'QR', code: 'QAR' },
  },
  SA: {
    code: 'SA',
    name: 'Saudi Arabia',
    index: 30,
    geid: 'HS_SA',
    currency: { symbol: 'SAR', code: 'SAR' },
  },
  AE: {
    code: 'AE',
    name: 'United Arab Emirates',
    index: 31,
    geid: 'TB_AE',
    currency: { symbol: 'DH', code: 'AED' },
  },
}

export const COUNTRY_LIST = {
  ...AP_COUNTRY_LIST,
  ...EU_COUNTRY_LIST,
  ...MENA_COUNTRY_LIST
};

export const REGION_COUNTRY = {
  AP: AP_COUNTRY_LIST,
  EU: EU_COUNTRY_LIST,
  ME: MENA_COUNTRY_LIST,
};
