import ManagePhoto from './containers/ManagePhoto';
import _isNil from 'lodash/isNil';
import DishPhotoReview from './containers/DishPhotoReview';
import { getPendingRequestCountOnInit, initPlugin } from '../initPlugin';
import { LayoutOne, LayoutTwo } from '../App/layouts/TabsLayout';
import Home from 'MenuManagement/containers/Home';

const PhotoManagement = ({ setPluginTitle, currentCountry, optionalPath = '' }) => {
  const pluginId = 'photo-management';
  const label = 'Photos';
  // const isTalabat = currentCountry.geid === 'TB_KW';

  const setRequestCount = (pluginId, labelText) => (count) => {
    setPluginTitle(pluginId, _isNil(count) ? labelText : `${labelText} (${count})`);
  };

  const photoManagement = {
    path: optionalPath || '/photos',
    visibleOnTabNavbar: true,
    component: ManagePhoto,
    parent: Home,
    label,
    isProtected: true,
    layout: LayoutOne,
    additionalProps: {
      setRequestCount: setRequestCount(pluginId, label),
    },
  };
  const photoReview = {
    path: '/changes/:countryCode/:vendorCode/photo/:item_id',
    visibleOnTabNavbar: false,
    component: DishPhotoReview,
    label: 'Dish Photo Review',
    isProtected: true,
    layout: LayoutTwo,
  };

  // const availablePages = isTalabat ? [photoReview] : [photoManagement, photoReview];
  const availablePages = [photoManagement, photoReview];

  const onInit = getPendingRequestCountOnInit(currentCountry, pluginId, setPluginTitle, label);

  return initPlugin(pluginId, availablePages, onInit);
};

export default PhotoManagement;
