import {
  Box,
  Checkbox,
  Collapse,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import moment from 'moment';
import AttachFile from '@material-ui/icons/AttachFile';
import AgentAssignButton from '../../components/AgentAssignButton';
import { Product } from './NewProductDetails';
import ChoiceGroup from './NewChoiceGroup';
import { tablestyles } from './styles';
import { UserContext } from '../../../App';
import _ from 'lodash';
import axios from '../../../App/utility/axios';
import config from '../../../App/config';
import useLazyVendorItems from '../../../hooks/useLazyVendorItems';
import LoadingState from 'components/LoadingState';
import {
  appendAgentQueueData,
  setCategoriesByVendor,
  setCheckedRequests,
  setChoiceGroupsByVendor,
  setCollapse,
  setGroupedChoiceGroupRequests,
  setGroupedProductRequests,
  setIsProcessingData,
  setVendorItems,
  setVendorNames,
} from '../../store/agentQueue/actions';
import useInViewport from '../../../hooks/useInViewport';
import { AgentQueueContext } from './AgentQueue';
import { TYPE_OF_CHANGE, TYPE_OF_CHANGE_CHOICE_GROUP } from '../../../App/common/constant';
import { getEmbeddedPatchDataIds } from './NewChoiceGroup/helpers';
import { useSelector } from 'react-redux';
const useTableStyles = makeStyles(tablestyles);

const AgentQueueTable = ({
  fwfFlags,
  currentCountry,
  onCopyVendorCode,
  processChangeRequests,
  store,
}) => {
  const currentUser = React.useContext(UserContext);
  const tableClasses = useTableStyles();
  const [embeddedVariantChoiceGroupData, setEmbeddedVariantChoiceGroupData] = useState([]);

  const AGENT_ASSIGNMENT_FEATURE = fwfFlags?.fwfAgentStatus?.variation || true;
  const isLazyLoadActive = fwfFlags.fwfLazyLoadAgentQueue.variation;
  const { state, dispatch, isFetchingData, fetchData } = React.useContext(AgentQueueContext);
  const [choiceGroupAPIStatus, setChoiceGroupAPIStatus] = useState(null);
  const filterState = useSelector((state) => state.filterState);
  const { tableRef, handleScroll, LIST_SIZE_TO_INITIALIZE } = useLazyVendorItems(
    store,
    isLazyLoadActive
  );

  //Loading trigger
  const isLoadingNext = state.isProcessing;
  const ALLOW_INFINITE_SCROLL = fwfFlags?.fwfInfiniteScroll?.variation;
  const loadRef = useRef();
  const loaderInView = useInViewport(loadRef, '0px'); // Trigger as soon as the element becomes visible
  const hasNextPage = !!state.pageToken;

  const fetchNextData = async () => {
    const inputParams = {
      globalEntityID: currentCountry.geid,
      contentType: null,
      timeFrom: filterState.payload.input.timeFrom ? `${filterState.payload.input.timeFrom}` : null,
      timeTo: filterState.payload.input.timeTo ? `${filterState.payload.input.timeTo}` : null,
      search: filterState.payload.input.search || null,
    };

    const variableParams = {
      assignedAgent: filterState.payload.assignedToMe ? currentUser.sub : null,
      requestIdSuffix:
        filterState.payload?.requestIdSuffix?.length >= 6
          ? filterState.payload?.requestIdSuffix
          : null,
    };

    const response = await fetchData({
      input: inputParams,
      ...variableParams,
      pageToken: state.pageToken,
    });

    return response.data;
  };

  const fetchAndSet = async () => {
    dispatch(setIsProcessingData(true));
    await fetchNextData().then((data) => {
      dispatch(appendAgentQueueData(data.agentQueue.qcRequests, data.agentQueue.pageToken));
    });
    dispatch(setIsProcessingData(false));
  };

  if (loaderInView) {
    if (hasNextPage) {
      fetchAndSet();
    }
  }

  useEffect(() => {
    const agentQueueData = state.agentQueueData?.length ? state.agentQueueData : [];
    // Create a vendor list from changes by getting one item for each.
    // Since we sort the changes by timestamp previously, the item we use for vendor will be the earliest request
    const vendorItemList = [];
    const vendorNames = {};
    agentQueueData.forEach(({ item, meta }, index) => {
      if (!vendorItemList[item.vendorCode]) {
        vendorItemList[item.vendorCode] = { ...item, index };
      }
      if (!vendorNames[item.vendorCode] && !!meta.vendorName) {
        vendorNames[item.vendorCode] = meta.vendorName;
      }
    });

    // Get rid of the custom keys and create a proper array
    dispatch(setVendorItems(Object.values(vendorItemList)));
    dispatch(setVendorNames(vendorNames));
  }, [state.agentQueueData, dispatch]);

  const getVendorProductRequests = useCallback((vendorCode, requests) => {
    return requests.filter(
      (request) =>
        Object.values(TYPE_OF_CHANGE).includes(request.item.typeOfChange) &&
        request.item.vendorCode === vendorCode
    );
  }, []);

  const getVendorChoiceGroupRequests = useCallback((vendorCode, requests) => {
    return requests.filter(
      (request) =>
        Object.values(TYPE_OF_CHANGE_CHOICE_GROUP).includes(request.item.typeOfChange) &&
        request.item.vendorCode === vendorCode
    );
  }, []);

  useEffect(() => {
    const productRequests = {};
    const choiceGroupRequests = {};
    state.vendorItems.forEach((vendorItem) => {
      const vendorProductRequests = getVendorProductRequests(
        vendorItem.vendorCode,
        state.agentQueueData
      );
      const vendorChoiceGroupRequests = getVendorChoiceGroupRequests(
        vendorItem.vendorCode,
        state.agentQueueData
      );
      productRequests[vendorItem.vendorCode] = groupById(vendorProductRequests);
      choiceGroupRequests[vendorItem.vendorCode] = groupById(vendorChoiceGroupRequests);
    });
    dispatch(setGroupedProductRequests(productRequests));
    dispatch(setGroupedChoiceGroupRequests(choiceGroupRequests));

    // only watch for data and vendor changes to update group request id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.agentQueueData, state.vendorItems]);

  const getFromBrandProxy = useCallback(
    async (itemType, vendorCode, itemId = '') => {
      try {
        const response = await axios(`${config.brandProxyApi}/${itemType}/${itemId}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-global-entity-id': currentCountry.geid,
            'x-vendor-id': vendorCode,
          },
        });
        return response.data.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [currentCountry.geid]
  );

  const getVendorRelatedData = async (vendorCode) => {
    if (!state.categoriesByVendor[vendorCode]) {
      getFromBrandProxy('categories', vendorCode).then((data) => {
        dispatch(setCategoriesByVendor({ ...state.categoriesByVendor, [vendorCode]: data }));
      });
      getFromBrandProxy('choice-groups', vendorCode).then((data) => {
        setChoiceGroupAPIStatus(true);
        dispatch(setChoiceGroupsByVendor({ ...state.choiceGroupsByVendor, [vendorCode]: data }));

        let embeddedVariantChoiceGroupAddData = [];
        let embeddedVariantCreateData = [];

        state.groupedProductRequests[vendorCode].forEach((groupRequest) => {
          groupRequest.forEach((request) => {
            if (request.item.typeOfChange === TYPE_OF_CHANGE.EMBEDDED_VARIANT_CHOICE_GROUP_ADD) {
              embeddedVariantChoiceGroupAddData.push(request);
            }
            if (request.item.typeOfChange === TYPE_OF_CHANGE.EMBEDDED_VARIANT_CREATE) {
              embeddedVariantCreateData.push(request);
            }
          });
        });

        let cgIds = [];
        let cgData = [];

        //Check EmbeddedVariantChoiceGroupAdd
        embeddedVariantChoiceGroupAddData.forEach((request) => {
          const choiceGroupId = Object.keys(
            request?.item?.patchData?.variants[request.item.itemId]?.choice_groups
          );
          let choiceGroupData = data && data.find((cg) => cg.id === choiceGroupId.toString());
          if (!choiceGroupData) {
            cgIds.push(choiceGroupId.toString());
          } else {
            choiceGroupData.baseItemId = request.baseItemId;
            cgData.push(choiceGroupData);
          }
        });

        //Check EmbeddedVariantCreate
        embeddedVariantCreateData.forEach((request) => {
          let variants = request.item.patchData.variants[request.item.itemId];
          let choiceGroupId;

          if (!variants) {
            variants = request.item?.patchData?.variants;
            Object.keys(variants).forEach((variant) => {
              choiceGroupId = variants[variant]?.choice_group_ids;
            });
          } else {
            choiceGroupId = Object.keys(variants?.choice_groups);
          }

          if (!choiceGroupId) return;

          let choiceGroupData = data && data.find((cg) => cg.id === choiceGroupId.toString());
          if (choiceGroupData) {
            choiceGroupData.baseItemId = request.baseItemId;
            cgData.push(choiceGroupData);
          } else {
            cgIds.push(choiceGroupId.toString());
          }
        });

        cgIds = _.uniqBy(cgIds);

        //Check new added Choice Groups
        let patchData;
        cgIds.forEach((cgId) => {
          state.groupedChoiceGroupRequests[vendorCode].forEach((groupRequest) => {
            groupRequest.forEach((request) => {
              if (request.item.itemId === cgId) {
                patchData = request.item.patchData;
                patchData = { ...patchData, id: cgId, baseItemId: request.baseItemId };
                cgData.push(patchData);
              }
            });
          });
        });

        cgData = _.uniqBy(cgData);
        setEmbeddedVariantChoiceGroupData(cgData);
      });
    }
  };

  const getBaseItemId = (request) => {
    //Variant changes have the productID in meta. Other change types have productID in the itemID field.
    let baseItemId = request?.meta?.productID ?? request?.item?.itemId;

    const typeOfChange = request.item.typeOfChange;

    //Group by choice group ID
    if (typeOfChange.startsWith('EmbeddedChoice')) {
      const { choiceGroupId } = getEmbeddedPatchDataIds(request.item.patchData);
      baseItemId = choiceGroupId;
    }
    //typeOfChange with ':' indicates a composite id with format "ProductId:VariantId"
    if (baseItemId && baseItemId.includes(':')) {
      const splitId = baseItemId.split(':');

      baseItemId = splitId[0];
    }

    return baseItemId;
  };

  const groupById = (qcRequests) => {
    const groupedRequests = {};

    qcRequests.forEach((request) => {
      const baseItemId = getBaseItemId(request);
      const item = { ...request, baseItemId };

      if (groupedRequests.hasOwnProperty(baseItemId)) {
        //Existing product
        groupedRequests[baseItemId].push(item);
      } else {
        //New product
        groupedRequests[baseItemId] = [item];
      }
    });

    return Object.values(groupedRequests);
  };

  const setGroupRequestsById = (vendorCode) => {
    const vendorProductRequests = getVendorProductRequests(vendorCode, state.agentQueueData);
    const vendorChoiceGroupRequests = getVendorChoiceGroupRequests(
      vendorCode,
      state.agentQueueData
    );
    dispatch(
      setGroupedProductRequests({
        ...state.groupedProductRequests,
        [vendorCode]: groupById(vendorProductRequests),
      })
    );
    dispatch(
      setGroupedChoiceGroupRequests({
        ...state.groupedChoiceGroupRequests,
        [vendorCode]: groupById(vendorChoiceGroupRequests),
      })
    );
  };

  const onClickVendorRow = (vendorCode) => (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const collapseClone = new Set(state.collapse);
    if (collapseClone.has(vendorCode)) {
      collapseClone.delete(vendorCode);
    } else {
      collapseClone.add(vendorCode);
      getVendorRelatedData(vendorCode);
      setGroupRequestsById(vendorCode);
    }
    dispatch(setCollapse(collapseClone));
  };

  const onVendorCheck = (vendorCode) => (evt) => {
    const changeRequests = state.agentQueueData.filter(
      (data) => data.item.vendorCode === vendorCode
    );
    evt.nativeEvent.stopImmediatePropagation();
    evt.stopPropagation();
    if (evt.target.checked) {
      dispatch(setCheckedRequests(_.union(state.checkedRequests, changeRequests)));
    } else {
      dispatch(setCheckedRequests(_.difference(state.checkedRequests, changeRequests)));
    }
  };

  const onClearChecked = () => {
    dispatch(setCheckedRequests([]));
  };

  const onCheckHandler = (callback) => {
    dispatch(setCheckedRequests(callback(state.checkedRequests)));
  };

  return (
    <Paper
      className={tableClasses.tableRoot}
      {...(isLazyLoadActive &&
        state.vendorItems.length >= LIST_SIZE_TO_INITIALIZE && {
          onScroll: handleScroll,
          ref: tableRef,
        })}
      data-enzyme={'AgentQueueTable'}
    >
      <Table className={tableClasses.table}>
        {(!isLazyLoadActive ||
          state.vendorItems[0]?.vendorCode === state.lazyVendorItems[0]?.vendorCode) && (
          <TableHead>
            <TableRow>
              <TableCell data-cp="vendor-name" align={'left'} className={tableClasses.vendorName}>
                Vendor Name
              </TableCell>
              <TableCell align={'left'} className={tableClasses.vendorCode}>
                Vendor Code
              </TableCell>
              <TableCell align={'left'} className={tableClasses.vendorName}>
                Earliest Timestamp
              </TableCell>
              <TableCell align={'left'} className={tableClasses.files}>
                Photo Uploaded
              </TableCell>
              {AGENT_ASSIGNMENT_FEATURE && (
                <>
                  <TableCell>Assigned Agent</TableCell>
                  <TableCell>Action</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {((isLazyLoadActive && state.lazyVendorItems) || state.vendorItems).map((item) => {
            const assignedAgent = item.assignedAgent || '-';
            return (
              <Fragment key={item.vendorCode}>
                <TableRow
                  onClick={onClickVendorRow(item.vendorCode)}
                  data-enzyme={'preview-row'}
                  data-cp={'preview-row'}
                >
                  <TableCell align={'left'}>
                    {assignedAgent && assignedAgent === currentUser.sub && (
                      <Checkbox onClick={onVendorCheck(item.vendorCode)} />
                    )}
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: '250px',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    >
                      {state.vendorNames?.[item.vendorCode] || item.vendorCode}
                    </div>
                  </TableCell>

                  <TableCell align={'left'} onClick={onCopyVendorCode(item.vendorCode)}>
                    <Box>
                      <FileCopy />
                      {item.vendorCode}
                    </Box>
                  </TableCell>

                  <TableCell
                    align={'left'}
                    className={tableClasses.submitDate}
                    data-enzyme={'submitted-date'}
                  >
                    {moment(item.timestamp, 'X').format('DD MMM YYYY, H:mm')}
                  </TableCell>

                  <TableCell align={'left'} className={tableClasses.files}>
                    <Link
                      onClick={(e) => e.stopPropagation()}
                      href={`/photo/menu/${item.vendorCode}/${currentCountry.code}`}
                      target={'_blank'}
                      rel={'noopener'}
                    >
                      <AttachFile />
                    </Link>
                  </TableCell>

                  {AGENT_ASSIGNMENT_FEATURE && (
                    <>
                      <TableCell data-enzyme={'agent-email'} className={tableClasses.agentEmail}>
                        {assignedAgent}
                      </TableCell>

                      <TableCell align={'right'}>
                        <AgentAssignButton
                          isAssigned={assignedAgent && assignedAgent !== '-'}
                          products={state.agentQueueData.filter(
                            (data) => data.item.vendorCode === item.vendorCode
                          )}
                          vendorCode={item.vendorCode}
                          currentUser={currentUser}
                          onClickVendorRow={onClickVendorRow}
                          clearChecks={onClearChecked}
                        />
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow data-enzyme={'data-row'} data-cp={'data-row'}>
                  <TableCell colSpan={12} style={{ padding: 0, border: 0 }}>
                    <Collapse
                      in={state.collapse.has(item.vendorCode)}
                      timeout={'auto'}
                      unmountOnExit
                      data-cp={'MuiCollapse-wrapper'}
                    >
                      <>
                        {state.groupedProductRequests?.[item.vendorCode]?.length > 0 && (
                          <Box bgcolor={'#f9f9f9'} p={3} pl={4} pr={4}>
                            {state.groupedProductRequests[item.vendorCode].map((requests) => {
                              const request = requests[0];
                              const productId = request.baseItemId;
                              return (
                                <Product
                                  key={request.requestId}
                                  productId={productId}
                                  changeArray={requests}
                                  categories={state.categoriesByVendor[item.vendorCode] || []}
                                  choiceGroups={state.choiceGroupsByVendor[item.vendorCode] || []}
                                  cgData={embeddedVariantChoiceGroupData || []}
                                  choiceGroupAPIStatus={choiceGroupAPIStatus}
                                  vatList={state.vats}
                                  checked={
                                    state.checkedRequests.findIndex(
                                      (r) => r.requestId === request.requestId
                                    ) !== -1
                                  }
                                  onCheck={onCheckHandler}
                                  showCheckbox={assignedAgent && assignedAgent === currentUser?.sub}
                                  onSaveChanges={processChangeRequests}
                                  fwfFlags={fwfFlags}
                                  isMQSProduct={
                                    request.requestId.includes('#MQS#') &&
                                    request.item.typeOfChange === TYPE_OF_CHANGE.PRODUCT_CREATE
                                  }
                                  mqsProductDetails={request.item.patchData}
                                />
                              );
                            })}
                          </Box>
                        )}
                        {state.groupedChoiceGroupRequests?.[item.vendorCode]?.length > 0 && (
                          <Box bgcolor={'#f9f9f9'} data-cp={'choice-group-section'} p={4}>
                            CHOICE GROUPS
                            {state.groupedChoiceGroupRequests[item.vendorCode].map((requests) => {
                              const request = requests[0];
                              return (
                                <ChoiceGroup
                                  key={request.requestId}
                                  choiceGroupId={request.item.itemId}
                                  changeArray={requests}
                                  checked={
                                    state.checkedRequests.findIndex(
                                      (r) => r.requestId === request.requestId
                                    ) !== -1
                                  }
                                  onCheckChange={onCheckHandler}
                                  showCheckbox={assignedAgent && assignedAgent === currentUser?.sub}
                                  fwfFlags={fwfFlags}
                                  isMQSChoiceGroup={request.requestId.includes('#MQS#')}
                                />
                              );
                            })}
                          </Box>
                        )}
                      </>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
          {hasNextPage && !isFetchingData && ALLOW_INFINITE_SCROLL && (
            <>
              {isFetchingData || isLoadingNext ? (
                <TableRow>
                  <TableCell colSpan={'100%'}>
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <LoadingState></LoadingState>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow onClick={fetchAndSet}>
                  <TableCell colSpan={'100%'}>
                    <div ref={loadRef} style={{ textAlign: 'center' }}>
                      Load more
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default AgentQueueTable;
